@tailwind base;

@layer base {
  :root {
    --spacing-3xs: 0.25rem;
    --spacing-1-6rem: 1rem;
    --spacing-xxs: 0.5rem;
    --spacing-xs: 1rem;
    --spacing-s: 1.5rem;
    --spacing-m: 2rem;
    --spacing-l: 3rem;
    --spacing-2l: calc(2 * var(--spacing-l));
    --spacing-xl: 4rem;
    --spacing-xxl: 5rem;
  }

  @screen mq4 {
    :root {
      --spacing-xs: 1.5rem;
      --spacing-s: 2rem;
      --spacing-m: 3rem;
      --spacing-l: 4rem;
      --spacing-xl: 5rem;
      --spacing-xxl: 5.5rem;
    }
  }

  @screen mq6 {
    :root {
      --spacing-l: 4.5rem;
      --spacing-xl: 5.5rem;
      --spacing-xxl: 6rem;
    }
  }
}
