@tailwind base;

@layer base {
  :root {
    --color-highlight: #00adef;
    --color-highlight--hover: #0088c6;
    --color-highlight--pressed: #009ed8;
    --color-highlight--outline-dark: #4a90e2;
    --color-highlight--outline-light: #57cdf5;
    --color-white: #ffffff;
    --color-smoke: #f8f8f8;
    --color-moon: #f4f4f4;
    --color-asphalt: #e5e5e5;
    --color-silver: #bfbfbf;
    --color-noble: #999999;
    --color-boulder: #767676;
    --color-gunmetal: #666666;
    --color-obsidian: #333333;
    --color-mineshaft: #262626;
    --color-black: #000000;
    --color-ok: #008a00;
    --color-caution: #ffbf00;
    --color-error: #e1292b;

    --color-blue-05: #00182c;
    --color-blue-10: #002441;
    --color-blue-15: #003156;
    --color-blue-20: #013c6b;
    --color-blue-25: #014880;
    --color-blue-30: #025497;
    --color-blue-35: #0260ab;
    --color-blue-40: #036dc1;
    --color-blue-45: #0078d6;
    --color-blue-50: #008dfc;
    --color-blue-55: #1998fc;
    --color-blue-60: #33a4fd;
    --color-blue-65: #4eaffd;
    --color-blue-70: #66bbfd;
    --color-blue-75: #80c6ff;
    --color-blue-80: #9ad2fe;
    --color-blue-85: #b4ddfe;
    --color-blue-90: #cce8ff;
    --color-blue-95: #e6f5ff;
    --color-green-05: #051a07;
    --color-green-10: #07260b;
    --color-green-15: #0a330f;
    --color-green-20: #0d4013;
    --color-green-25: #0f4d16;
    --color-green-30: #125a1a;
    --color-green-35: #14661e;
    --color-green-40: #177321;
    --color-green-45: #198025;
    --color-green-50: #21a330;
    --color-green-55: #37ac45;
    --color-green-60: #4db559;
    --color-green-65: #64bf6e;
    --color-green-70: #7ac883;
    --color-green-75: #90d198;
    --color-green-80: #a6daac;
    --color-green-85: #bce3c1;
    --color-green-90: #d3edd6;
    --color-green-95: #e9f6ea;
    --color-grey-05: #0d0d0d;
    --color-grey-10: #1a1a1a;
    --color-grey-15: #272727;
    --color-grey-20: #333;
    --color-grey-25: #424242;
    --color-grey-30: #4f4f4f;
    --color-grey-35: #5c5c5c;
    --color-grey-40: #696969;
    --color-grey-45: #767676;
    --color-grey-50: #848484;
    --color-grey-55: #919191;
    --color-grey-60: #9f9f9f;
    --color-grey-65: #adadad;
    --color-grey-70: #bbb;
    --color-grey-75: #c9c9c9;
    --color-grey-80: #dbdbdb;
    --color-grey-85: #e8e8e8;
    --color-grey-90: #f4f4f4;
    --color-grey-95: #f8f8f8;
    --color-red-05: #2b0707;
    --color-red-10: #410a0a;
    --color-red-15: #570d0d;
    --color-red-20: #6d1111;
    --color-red-25: #821414;
    --color-red-30: #981717;
    --color-red-35: #ae1a1a;
    --color-red-40: #c31e1e;
    --color-red-45: #d92121;
    --color-red-50: #ff4a4a;
    --color-red-55: #ff5c5c;
    --color-red-60: #ff6e6e;
    --color-red-65: #ff8080;
    --color-red-70: #ff9292;
    --color-red-75: #ffa5a5;
    --color-red-80: #ffb7b7;
    --color-red-85: #ffc9c9;
    --color-red-90: #ffdbdb;
    --color-red-95: #ffeded;
    --color-yellow-05: #2e2600;
    --color-yellow-10: #463900;
    --color-yellow-15: #5d4c00;
    --color-yellow-20: #745f00;
    --color-yellow-25: #8b7100;
    --color-yellow-30: #a28400;
    --color-yellow-35: #ba9700;
    --color-yellow-40: #d1aa00;
    --color-yellow-45: #e8bd00;
    --color-yellow-50: #facc00;
    --color-yellow-55: #fbd11a;
    --color-yellow-60: #fbd633;
    --color-yellow-65: #fcdb4c;
    --color-yellow-70: #fce066;
    --color-yellow-75: #fde680;
    --color-yellow-80: #fdeb99;
    --color-yellow-85: #fef0b3;
    --color-yellow-90: #fef5cc;
    --color-yellow-95: #fffae6;

    --shadow-level-1: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    --shadow-level-2: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    --shadow-level-3: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  }
}
