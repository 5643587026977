@use '@workbench/core/dist/scss/_utility.scss' as utility;

.demo-video-playlist-button{
    min-width: 195px;
    @include utility.breakpoint-to(mq1) {
        min-width: 100%;
    }
}

.demo-vin-vehicle-button {
    min-width: 195px;
    margin-left: var(--wb-spacing-s);
    @include utility.breakpoint-to(mq1) {
        margin-top: var(--wb-spacing-s);
        margin-left: 0;
        min-width: 100%;
    }
}