@import 'tailwindcss/base';
@import './custom-base.css';

@import 'tailwindcss/components';
/* @import "./custom-components.css"; */

@import 'tailwindcss/utilities';
/* @import "./custom-utilities.css"; */

@media screen and (max-width: 768px) {
    .top-components-section {
      padding: 0;
    }
    .top-components {
      flex-wrap: wrap-reverse;
      width: 100vw;
    }
    .top-image-holder{
        width: 100vw;
        justify-content: center;
        padding: 50px;
    }
    .header-holder{
        width: 100vw;
        padding: 0 50px 50px 50px;
    }
}