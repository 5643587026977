@use '@workbench/core/dist/scss/_utility.scss' as utility;
@import '@workbench/core/dist/scss/utility.scss';

.rd-vehicle-card {
	@include wb-spacing(padding, 'm 0');
	&__detail {
		margin-bottom: var(--wb-spacing-xxs);
		padding: 0 var(--wb-spacing-xs);
	}
}

.vehicle-list {
	list-style: none;
	width: 95%;
	margin: 0;
	padding: 0;
	padding-left: var(--wb-spacing-xs);
	&__item {
		margin-left: -5px;
		border-bottom: 1px solid #e5e5e5;
		padding: 16px var(--wb-spacing-xxs);
	}
	&__item:last-child {
		border-bottom: none;
	}
	&__icon {
		width: 16px;
		fill: currentColor;
		color: #bfbfbf;
		margin-right: var(--wb-spacing-xs);
	}
}
