@use '@workbench/core/dist/scss/_utility.scss' as utility;
@import '@workbench/core/dist/scss/utility.scss';

.demo-vin__container {
    background-color: #f8f8f8;
    margin-bottom: var(--wb-spacing-s);
}

.rd-demo-vin-heading {
	@include wb-spacing(padding, 'm s s s');
	wb-heading {
		margin-bottom: var(--wb-spacing-s);
	}
	background-color: white;
	border-bottom: 1px solid var(--wb-grey-85);
	margin-bottom: var(--wb-spacing-l);

	&__text {
		@include breakpoint-to(mq4) {
			margin-bottom: var(--wb-spacing-s);
		}
	}

	
}

.rd-demo-vin-input {
	@include wb-spacing(padding, '0 m');
    padding-bottom: var(--wb-spacing-s);
	position: relative;

	&__container {
        margin-bottom: var(--wb-spacing-s);
		@include breakpoint-to(mq4) {
			margin-bottom: var(--wb-spacing-s);
		}
	}

    &__label {
        display: flex;
        align-items: center
    }

    &__button {
		width: 100%;
		height: 100%;
        float: right;
        @include utility.breakpoint-to(mq4) {
            min-width: 100%;
        }
	}
}
.rd-demo-market-select {
	padding-left: 15px;
}