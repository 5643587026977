.rd-box{
    display: flex;
    &--vertical{
        flex-direction: column;
    }

    &--align-start{
        align-items: start;
    }
    &--align-center{
        align-items: center;
    }
    &--align-end{
        align-items: end;
    }
    
}