div.App {
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	> header {
		height: 160px;
	}
	> main {
		flex: auto;
	}
	> footer {
		background-color: #000;
	}
}
