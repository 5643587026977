@use '@workbench/core/dist/scss/_utility.scss' as utility;
@import '@workbench/core/dist/scss/utility.scss';

.rd-demo-image-preview {
	height: 100%;

	&__360-image {
		cursor: pointer;
		width: 10vw;
		position: absolute;
		top: 20px;
		left: calc(20px + var(--wb-spacing-m));
		border-width: 2px;
		border-style: solid;
		border-color: white;
	}

	&__360-image:hover {
		border-color: var(--wb-blue-45);
	}

	&__controller {
		border-radius: 20px;
		margin: auto;
		padding: var(--wb-spacing-xs);
		height: auto;
	}

	&__image-container {
		position: sticky !important;
		width: 100%;
		aspect-ratio: 16/9;
	}
}

.rd-demo-info {
	@include wb-spacing(padding, '0 m');
	position: relative;

	&__trunk-image {
		width: 99%;
	}
}

:fullscreen .rd-demo-image-gallery{
	max-height: 80% !important;
}