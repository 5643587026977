@use '@workbench/core/dist/scss/_utility.scss' as utility;
@import '@workbench/core/dist/scss/utility.scss';

.rd-demo-heading {
	@include wb-spacing(padding, 'm s s s');
	wb-heading {
		margin-bottom: var(--wb-spacing-s);
	}
	background-color: white;
	border-bottom: 1px solid var(--wb-grey-85);
	margin-bottom: var(--wb-spacing-l);

	&__text {
		@include breakpoint-to(mq4) {
			margin-bottom: var(--wb-spacing-s);
		}
	}

	&__buttons {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.rd-demo-info {
	@include wb-spacing(padding, '0 m');
	position: relative;

	&__gallery {
		position: relative;
	}

}

.rd-vehicle-tab-info {
	padding: 0;
}

.rd-demo-info__galery:fullscreen::backdrop{
	background-color: #f8f8f8;
}
