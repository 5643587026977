@import '@workbench/core/dist/scss/_utility.scss';

.equipmentDetail{
    &__coreArgument{
        padding: var(--wb-spacing-xxs) var(--wb-spacing-xxs);
    }
    &__headline{
        padding: 0 var(--wb-spacing-xxs);
    }
    &__detail{
        margin-bottom: var(--wb-spacing-xxs);
    }
}