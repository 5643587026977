.overlay {
  position: fixed;
  inset: 0;
}

.modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.7s forwards;
}

@keyframes fadeIn {
  from {
    background: rgba(0, 0, 0, 0.5);
  }
  to {
    background: rgba(0, 0, 0, 0.85);
  }
}
