h3 {
    font-size: 1.5rem;
    color: #333;
    margin: 1rem 0;
    font-weight: bold;
}

h2 {
    font-size: 2rem;
    color: #222;
    margin: 1.5rem 0;
    font-weight: semi-bold;
}

a {
    color: blue;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

span {
    span {
        &:not(:last-child) {
            &:after {
                content: ', ';
                margin-left: 0.25rem; // Adjust spacing as needed
            }
        }
    }
}