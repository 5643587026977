@use '@workbench/core/dist/scss/_utility.scss' as utility;
@import '@workbench/core/dist/scss/utility.scss';
.rd-demo {
	width: 100%;
	gap: var(--wb-spacing-m) !important;
	@include wb-spacing(padding, 'm 0');
}

.rd-demo-header {
	gap: var(--wb-spacing-m) !important;
	&__navigation {
		--color: var(--wb-blue-45) !important;
	}
	&__title{
		width: 100%;
		gap:var(--wb-spacing-xs) !important;
	}
	&__main {
		--vertical-grid-gap: var(--wb-spacing-xs) !important;
	}
	&__menu {
		display: flex;
		justify-content: flex-start;
		@include utility.breakpoint-to(mq4) {
			justify-content: flex-start;
		}
	}
}
