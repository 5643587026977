@tailwind base;

@layer base {
  :root {
    --grid-gutter-width: 1rem;
    --grid-content-width: 90vw;
    --grid-max-width: 105rem;
  }

  @screen mq3 {
    :root {
      --grid-gutter-width: 1.5rem;
      --grid-content-width: 86vw;
    }
  }

  @screen mq5 {
    :root {
      --grid-gutter-width: 2rem;
      --grid-content-width: 86vw;
    }
  }

  @screen mq6 {
    :root {
      --grid-gutter-width: 2.5rem;
    }
  }

  .grid-container {
    width: var(--grid-content-width);
    max-width: var(--grid-max-width);
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  .grid-row {
    display: flex;
    flex-flow: wrap;
    margin-left: calc(-0.5 * var(--grid-gutter-width));
    margin-right: calc(-0.5 * var(--grid-gutter-width));
  }
}
