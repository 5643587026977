
.video-js .vjs-control-bar {
	background-color: rgba(43, 51, 63, 0) !important;
}

.vjs-big-play-centered .vjs-big-play-button {
	margin-top: -0.8em !important;
	margin-left: -0.8em !important;
	height: 3rem !important;
	width: 3rem !important;
	background-color: black !important;
	border: none !important;
	border-radius: 100% !important;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
	background-color: rgba(0, 0, 0, 0.6) !important;
}

.video-js .vjs-picture-in-picture-control {
	display: none;
}
