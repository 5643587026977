@import '@workbench/core/dist/scss/_utility.scss';

.logo__retailer-dashboard {
	height: 20px;
	width: 220px;
	background-image: url('../../../Assets/Images/retailer_dashboard_logo.svg');
	background-repeat: no-repeat;
	background-size: auto;
}
.logo__mb-developers {
	height: 65px;
	width: 517px;
	background-image: url('../../../Assets/Images/MBDEV_Logo.png') !important;
	background-size: contain;
	background-repeat: no-repeat;
	@media screen and (max-width: 320px) {
		height: 40px;
		width: 310px;	
	}
}
.language-change_icon {
	background-image: url('../../../Assets/Images/wb_icon.svg');
	width: 16px;
	height: 16px;
}

.top-header {
	height: 100px;
	width: 100%;
	background-color: #000;
	&__expand-icon{
		@include breakpoint-from(mq3) {
			display: none;
		}
	}
	&__MobileMenuIcon {
		color: #fff;
		width: 30px;
		height: 30px;
	}
	&__ExpandedHeaderLinks {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		background-color: black;
		padding-bottom: 12px;
		z-index: 999;
		width: 100vw !important;
		height: 250px;
		position: absolute;
		top: 100px;
		left: -5vw;
		>span {
			margin: 10px 0;
			margin-right: 30px;
			>a{
				color: white;
				text-decoration: none;
			}
		}
	}
	> wb-grid {
		height: 100%;
		> wb-grid-row {
			height: 100%;
			align-content: center;
			> wb-grid-col {
				align-self: center;
				&:first-child{
					justify-self: start !important;
				}
				&:nth-child(2) {
					justify-self: end !important;
				}
			}
		}
	}
}

.sub-header {
	height: 40px;
	background-color: #262626 !important;
	&-grid{
		height: 100%;
		width: 100vw;
	}
	&-container{
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		width: 86vw;
		margin: auto;
	}
	&-hoverLinks{
		display: flex;
		align-items: center;
		@include breakpoint-to(mq2) {
			display: none;
		}
	}
	&__HeaderLinks-hover{
		height: 1px;
		background-color: #008DFC;
		top: 0;
		margin-left: 0;
		position: absolute;
		transition: left 0.5s, width 0.5s;
	}
	&__HeaderLink{
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0 15px;
		>a{
			color: #9D9588;
			text-decoration: none;
			font-size: 80%;
		}
		>a:hover{
			color: white;
		}
	}
	&__language{
		justify-self: end;
		&-change {
			color: #767676;
			cursor: pointer;
		}
	}
	&__logo{
		> .logo__retailer-dashboard {
			height: 16px;
			background-size: contain;
		}
	}
}
