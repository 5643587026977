.ReactModal__Overlay {
  opacity: 0;
  z-index: 10;
  transform: translateY(-50px);
  transition: all 0.25s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-50px);
}

.mute_btn_container {
  display: none;
}

.__blings__ctrl-wrapper {
  display: none !important;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

.player-close{
  padding-right: 1rem;
  z-index: 1000;
}