@use '@workbench/core/dist/scss/_utility.scss' as utility;
@import '@workbench/core/dist/scss/utility.scss';

.rd-demo-controller {
	gap: var(--wb-spacing-xs) !important;
	height: max-content !important;
	display: flex;
	align-items: center;
	justify-content: center;
	@include breakpoint-to(mq2) {
		flex-direction: column;
	}
	&__button {
		background-color: white;
		border-radius: 50%;
		margin: 0 var(--wb-spacing-xxs);
	}
	&__icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0.0625rem solid var(--wb-grey-85);
		border-radius: 50%;
		width: 3rem;
		height: 3rem;
		&.active {
			background-color: var(--wb-grey-85);
		}
	}
	&__icon-container:hover {
		cursor: pointer;
		background-color: var(--wb-grey-75);
	}
	&__icon-container:active {
		cursor: pointer;
		background-color: var(--wb-grey-85);
	}
}

.rd-demo-divider {
	width: 1px;
	height: 40px;
	margin-top: 5px;
	@include breakpoint-to(mq2) {
		width: 100vw;
		height: 0px;
	}
}

.rd-demo-divider__line { // not working as &__line (somehow...) TODO: fix this issue.
	width: 1px;
	height: 40px;
	margin: auto;
	background-color: var(--wb-grey-70);
	@include breakpoint-to(mq2) {
		width: 30vw;
		height: 1px;
	}
}

.rd-controller-buttons {
	&__left {
		display: flex;
		width: 50%;
		flex-direction: row-reverse;
		@include breakpoint-to(mq2) {
			width: 100%;
			justify-content: center;
		}
	}
	&__right {
		display: flex;
		width: 50%;
		@include breakpoint-to(mq2) {
			width: 100%;
			justify-content: center;
		}
	}
}
