@import '@workbench/core/dist/scss/_utility.scss';

.rd-card {
  &__mainImageContainer {
    position: relative;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-left: var(--wb-spacing-xs);
  }

  &__mainImageOverlay {
    min-height: 50px;
    display: flex;
    align-items:center;
    justify-content: start;
    background-color: var(--wb-grey-85);
    opacity: 0.9;
    width: 100%;
    bottom: 0px;
  }

  &__tag  {
    top: 0px;
    right: 0px;
    position: absolute;
    margin: var(--wb-spacing-xxs);
    padding: var(--wb-spacing-xxs);
    &--topLeft {
       left: 0;
    }
  }


  &__icon {
    margin-right: var(--wb-spacing-xxs);
    width: 26px;
  }

  &__slider {
    wb-slider-item {
      width: 100%;
    }

    wb-dot-navigation {
      margin-bottom: 10px !important
    }
  }

  &__content {
    height: 100%;
    position: relative;
  }

  &__buttonBar {
    z-index: 99;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: var(--wb-spacing-xs);
    height: 50px;
  }

  &__title {
    margin: var(--wb-spacing-xxs);
    text-align: start;
  }

  &__description {
    @include breakpoint-between(mq2,mq3) {
      min-height: 150px;
    }
    
    @include breakpoint-from(mq3) {
      min-height: 250px;
    }

    max-height: 250px;
    overflow: auto;
    word-break: normal;
    hyphens: auto;
    text-align: left;
    padding: var(--wb-spacing-xxs) var(--wb-spacing-xs);

    ul {
      padding-inline-start: var(--wb-spacing-xs);
    }
  }
}

.rd-card-details {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    top: 100%;
    overflow: hidden;
    max-height: 0%;
    transition: top 0.3s ease, max-height 0.3s ease;
}

.rd-card-details-closed {
  top: 100%;
  max-height: 0%;
  overflow: hidden;
}

.rd-card-details-open {
    opacity: 1;
    top: 0%;
    max-height: 100%;
    
    &__header {
      height: fit-content;
      padding: var(--wb-spacing-xxs) 0;
      justify-content: space-between;
      display: flex;
      align-items: center;
      background-color: var(--wb-white);
    }
    
    &__heading {
      padding: var(--wb-spacing-3xs)
    }
    
    &__headerText {
      margin: var(--wb-spacing-xs);
      text-align: center;
    }
    
    &__closeIcon {
      cursor: pointer;
      margin: var(--wb-spacing-3xs)
    }
    
    &__content {
      padding: var(--wb-spacing-xxs);
      background-color: var(--wb-grey-95);
      height: 100%;
      overflow: overlay;
      ul {
        padding-inline-start: var(--wb-spacing-xs);
      }
    }
}