.fadeIn {
  animation: fadeIn 8s infinite;
}

.swipe {
  animation: swipe 8s infinite;
}

.slide {
  animation: slide 8s infinite;
}
@keyframes fadeIn {
  0%,
  50%,
  100% {
    opacity: 0;
  }
  5%,
  45% {
    opacity: 100;
  }
}

@keyframes swipe {
  4% {
    opacity: 0;
  }
  9%,
  45% {
    opacity: 100;
  }
  50%,
  100% {
    opacity: 0;
  }

  0%,
  12% {
    transform: rotate(23deg) translate(5px);
  }

  25% {
    transform: rotate(-10deg) translate(-2px);
  }

  37%,
  100% {
    transform: rotate(23deg) translate(5px);
  }
}
@keyframes slide {
  12% {
    transform: translate(0px);
  }

  25% {
    transform: translate(-100px);
  }

  37%,
  100% {
    transform: translate(0px);
  }
}

.highlight {
  animation: click 0.3s;
}

@keyframes click {
  0%,
  100% {
    transform: scale(100%);
  }
  45% {
    transform: scale(97%);
  }
}

/* ----- NEW ----- */

.stack {
  grid-area: 1 / 1 / 2 / 2;
}
