@import '@workbench/core/dist/scss/utility.scss';

.rd-demo-content {
	width: 100%;
	@include wb-spacing(padding, 'l 0');
	padding-bottom: 0;
	&__tabs {
		width: 100%;
	}
	&__tab {
		background-color: #fff;
		padding-left: var(--wb-spacing-l);
		padding-right: var(--wb-spacing-l);
	}
	&__heading {
		margin-bottom: var(--wb-spacing-xs);
		margin-top: var(--wb-spacing-xs);
	}
	&__element {
		padding-bottom: var(--wb-spacing-xs);
	}
	&__key {
		color: #767676;
	}
}

.rd-demo-line-content{
	margin-bottom: var(--wb-spacing-xs);
}

.rd-demo-lineImage{
	height: 60px;
	width: 60px;
}

.rd-demo-image-box{
	display: flex;
	width: auto;
	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
}

.rd-demo-image-box-description, .rd-demo-line-box{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	>wb-text{
		display: flex;
		flex: 0.5;
	}
	>:nth-child(2){
		font-weight: 700;
	}
}