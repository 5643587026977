@tailwind base;

@layer base {
  @font-face {
    font-display: swap;
    font-family: MBCorpo Title;
    font-style: normal;
    font-weight: 400;
    src: url('../../../Fonts/MBCorpoATitleCond-Regular-Web.woff2') format('woff2'),
      url('../../../Fonts/MBCorpoATitleCond-Regular-Web.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: MBCorpo Text;
    font-style: normal;
    font-weight: 400;
    src: url('../../../Fonts/MBCorpoSText-Regular-Web.woff2') format('woff2'),
      url('../../../Fonts/MBCorpoSText-Regular-Web.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: MBCorpo Text;
    font-style: normal;
    font-weight: 700;
    src: url('../../../Fonts/MBCorpoSText-Bold-Web.woff2') format('woff2'),
      url('../../../Fonts/MBCorpoSText-Bold-Web.woff') format('woff');
  }
}
