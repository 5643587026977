@tailwind base;

@layer base {
  :root {
    --font-size-xxl: 3rem;
    --font-size-xl: 2.5rem;
    --font-size-l: 2rem;
    --font-size-m: 1.625rem;
    --font-size-s: 1.25rem;
    --font-size-xs: 1.125rem;
    --font-size-copy-strong: var(--font-size-copy);
    --font-size-copy: 1em;
    --font-size-copy-secondary: 0.875rem;
    --font-size-copy-tertiary: 0.75rem;
    --font-size-hint: 0.75rem;
    --font-size-hint-strong: var(--font-size-hint);
    --font-size-button: 1rem;
    --font-size-button-secondary: 0.875rem;
    --font-size-label: 1rem;
    --leading-xxl: 3.5rem;
    --leading-xl: 3rem;
    --leading-l: 2.5rem;
    --leading-m: 2rem;
    --leading-s: 1.75rem;
    --leading-xs: 1.75rem;
    --leading-copy-strong: var(--leading-copy);
    --leading-copy: 1.5rem;
    --leading-copy-secondary: 1.25rem;
    --leading-copy-tertiary: 1rem;
    --leading-hint: 1rem;
    --leading-hint-strong: var(--leading-hint);
    --leading-button: 1.5rem;
    --leading-button-secondary: 1.25rem;
    --leading-label: 1.5rem;
    --tracking-xxl: 0;
    --tracking-xl: 0;
    --tracking-l: 0;
    --tracking-m: 0;
    --tracking-s: 0;
    --tracking-xs: 0;
    --tracking-copy-strong: 0;
    --tracking-copy: 0;
    --tracking-copy-secondary: 0;
    --tracking-copy-tertiary: 0.0125em;
    --tracking-hint: 0.01875em;
    --tracking-hint-strong: var(--tracking-hint);
    --tracking-button: 0.025em;
    --tracking-button-secondary: 0.0125em;
    --tracking-label: 0;
  }

  @screen mq4 {
    :root {
      --font-size-xxl: 4.5rem;
      --font-size-xl: 4rem;
      --font-size-l: 3rem;
      --font-size-m: 2.125rem;
      --font-size-s: 1.5rem;
      --font-size-xs: 1.25rem;
      --font-size-copy: 1.125rem;
      --font-size-copy-secondary: 1rem;
      --font-size-copy-tertiary: 0.875rem;
      --leading-xxl: 5rem;
      --leading-xl: 4.5rem;
      --leading-l: 3.75rem;
      --leading-m: 2.75rem;
      --leading-s: 2rem;
      --leading-copy: 1.75rem;
      --leading-copy-secondary: 1.5rem;
      --leading-copy-tertiary: 1.25rem;
      --leading-hint: 1rem;
      --leading-button: 1.5rem;
      --leading-button-secondary: 1.25rem;
      --leading-label: 1.5rem;
    }
  }

  .text-xxl,
  .text-xl,
  .text-l,
  .text-m {
    @apply font-serif font-normal;
  }

  .text-s,
  .text-xs,
  .text-copy-strong,
  .text-copy-tertiary,
  .text-hint-strong {
    @apply font-sans font-bold;
  }

  .text-button {
    @apply font-sans font-bold;
  }

  /* Prevent headlines to receive unwanted font weight and styles due to wrong child elements, e. g. h3 > strong or h2 > em. */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    b,
    strong {
      font-weight: inherit;
    }

    i,
    em {
      font-style: inherit;
    }
  }

  .text-copy {
    > * + * {
      margin-top: 1em;
    }

    h1:not([class*='text-']) {
      @apply font-serif text-xxl;

      & + * {
        margin-top: theme('spaces.xs');
      }
    }

    h2:not([class*='text-']) {
      @apply text-xl;

      & + * {
        margin-top: theme('spaces.xs');
      }
    }

    h3:not([class*='text-']) {
      @apply text-l;
    }

    h4:not([class*='text-']) {
      @apply text-m;
    }

    h5:not([class*='text-']) {
      @apply text-s;
    }

    h6:not([class*='text-']) {
      @apply text-xs;
    }

    > ul li {
      padding-left: theme('spaces.s');
      position: relative;

      &::before {
        background: theme('colors.highlight.DEFAULT');
        content: '';
        height: 8px;
        left: 0;
        position: absolute;
        top: 0.6rem;
        transform: translateY(-50%);
        width: 8px;

        @screen mq4 {
          top: 0.75rem;
        }
      }
    }

    > ul li + li {
      margin-top: theme('spaces.s');
    }

    a:not(.button) {
      border-bottom: 1px solid;
      color: theme('colors.highlight.DEFAULT');
      text-decoration: none;

      &:hover {
        color: theme('colors.highlight.hover');
      }
    }
  }

  .text-copy-secondary {
    > * + * {
      margin-top: 1em;
    }

    h1:not([class*='text-']) {
      @apply font-serif text-xl;

      & + * {
        margin-top: theme('spaces.xs');
      }
    }

    h2:not([class*='text-']) {
      @apply font-serif text-l;

      & + * {
        margin-top: theme('spaces.xs');
      }
    }

    h3:not([class*='text-']) {
      @apply font-serif text-l;
    }

    h4:not([class*='text-']) {
      @apply font-serif text-m;
    }

    h5:not([class*='text-']) {
      @apply text-s font-bold;
    }

    h6:not([class*='text-']) {
      @apply text-xs font-bold;
    }

    > ul:not(:first-child) {
      margin-top: theme('spaces.2xxs');
    }

    > ul li {
      padding-left: theme('spaces.s');
      position: relative;

      &::before {
        background: theme('colors.highlight.DEFAULT');
        content: '';
        height: 8px;
        left: 0;
        position: absolute;
        top: 0.6rem;
        transform: translateY(-50%);
        width: 8px;

        @screen mq4 {
          top: 0.75rem;
        }
      }
    }

    > ul li + li {
      margin-top: theme('spaces.xxs');
    }

    a:not(.button) {
      border-bottom: 1px solid;
      color: theme('colors.highlight.DEFAULT');
      text-decoration: none;

      &:hover {
        color: theme('colors.highlight.hover');
      }
    }
  }

  .copy,
  .mm-ocd .mm-spn .copy {
    a {
      padding: 0;
      margin: 0;
      display: inline;
      color: theme('colors.highlight.DEFAULT');
      text-decoration: underline;
    }
  }

  .text-button-secondary {
    a {
      @extend .link;
      @extend .link--inline;
    }
  }

  .intro-section {
    .text-copy {
      > ul li {
        @apply font-bold;
      }

      > ul li + li {
        margin-top: theme('spaces.xs');
      }
    }

    .text-copy-secondary {
      ul + p {
        margin-top: theme('spaces.xs');
      }
    }

    .intro-left {
      .button-control {
        &:last-child {
          margin-top: theme('spaces.m');
        }
      }
    }
  }

  .accordion-item {
    .text-copy {
      > ul li + li {
        margin-top: 0;
      }
    }
  }

  .text-pages-content {
    .text-copy-secondary {
      > ul li {
        @apply font-normal;
      }

      ul {
      }

      li {
        counter-increment: section;
        padding-left: 1.5em;
        position: relative;

        &::before {
          content: counter(section, lower-alpha) '.';
          left: 0;
          position: absolute;
          top: 0;
        }
      }

      li + li {
        margin-top: theme('spaces.xs');
      }
    }
  }
}
