@tailwind base;

@layer base {
  :root {
    /** =======================================
    Container
    =========================================== */
    --max-container-width: 1680px;

    /** =======================================
    SPACES
    =========================================== */
    --space-outer-margin: 5vw;

    @screen mq3 {
      --space-outer-margin: 7vw;
    }

    /** =======================================
      COLORS
      =========================================== */
    --text-color: var(--color-obsidian);
    --bg-color: var(--color-transparent);

    [data-color-mode='dark'] {
      --text-color: var(--color-white);
      --bg-color: var(--color-black);
    }
  }
}
