@use '@workbench/core/dist/scss/_utility.scss' as utility;
.getting-started {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
}

.gs-main {
	margin-top: var(--wb-spacing-m);
	@include utility.breakpoint-from(mq3) {
		--wb-grid-gutter-width: var(--wb-spacing-m);
	}
	> wb-grid-row > wb-grid-col {
		@include utility.breakpoint-from(mq4) {
			max-width: 768px;
			&:last-child {
				justify-self: end;
			}
		}
	}

	&__video {
		display: block !important;
		@include utility.breakpoint-to(mq3) {
			display: none !important;
		}
	}
}
.gs-intro {
	&__title {
		margin-top: -10px;
	}
	&__video {
		display: none !important;
		@include utility.breakpoint-to(mq3) {
			display: block !important;
			margin-bottom: var(--wb-spacing-xs);
		}
	}
	&__title,
	&__text {
		color: #333;
		margin-bottom: var(--wb-spacing-xs);
	}
	&__info {
		color: #333;
		display: flex;
		gap: var(--wb-spacing-xxs);
		margin-bottom: var(--wb-spacing-xs);
	}
	&__start-button {
		min-width: 195px;
		margin-bottom: var(--wb-spacing-m);
		@include utility.breakpoint-to(mq1) {
			min-width: 100%;
		}
	}
}

.gs-example {
	&__video {
		width: 35vw;
		margin-right: var(--wb-spacing-m);
		@include utility.breakpoint-to(mq3) {
			width: 100%;
			margin: auto;
		}
	}
	&-actions {
		gap: var(--wb-spacing-xs) !important;
		@include utility.breakpoint-to(mq1) {
			flex-direction: column;
		}
		&__explore-button {
			min-width: 195px;
			@include utility.breakpoint-to(mq1) {
				min-width: 100%;
			}
		}
		&__start-button {
			min-width: 195px;
			@include utility.breakpoint-to(mq1) {
				min-width: 100%;
			}
		}
		&__video-playlist-button {
			min-width: 195px;
			margin-left: var(--wb-spacing-s);
			@include utility.breakpoint-to(mq1) {
				margin-top: var(--wb-spacing-s);
				margin-left: 0;
				min-width: 100%;
			}
		}
	}
}

.gs__product-key {
	width: 100%;
	border-top: 1px solid #e8e8e8;
	margin-top: var(--wb-spacing-s);
	&__text {
		margin: var(--wb-spacing-xs) 0px;
	}
}
