@import '@workbench/core/dist/scss/utility.scss';

.navigation {
  @include wb-spacing(padding, 's 0');
}

.container {
  background-color: var(--wb-grey-95);
}

.cta_footer {
    display: flex;
    align-items: center;
    height: 160px;

    &__content {
      align-items: center;
    }

    &__toggleBtn {
      cursor: pointer;
      --border: none;
      position: absolute;
      bottom: 109px;
      right: 137px;

      > wb-icon {
        width: 48px;
        height: 48px;
        margin: 0 var(--wb-spacing-xxs);
        object-fit: contain;
      }
    }

    &__sticky {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 99;
      
      &-open {
        height: 114px;
      }

      &-closed {
        height: 8px;
        .cta_footer__toggleBtn {
          bottom: 3px;
        }
       .cta_footer__content {
          display: none;
        }
      }

      @include breakpoint-between(mq1,mq3) {
        &-open {
          height: 190px;
          .cta_footer__toggleBtn {
            right: 20px;
            bottom: 185px;
          }
        }

        &-closed {
          height: 8px;
          .cta_footer__toggleBtn {
            right: 20px;
            bottom: 3px;
          }
         .cta_footer__content {
            display: none;
          }
        }
      }
      @include breakpoint-to(mq1){
        .cta_footer__toggleBtn {
          right: 20px;
        }
      }
    }

    &__snappy {
      @include wb-spacing(padding, '0 m');
      .cta_footer__toggleBtn {
        display: none;
      }
    }

    &__text {
      @include wb-spacing(margin, 'xxs 0');
      color: var(--wb-white);
      > wb-text {
        @include wb-text('m', ( responsive: true ));
        > b {
          @include wb-text('m', ( responsive: true, strong: true ));
        }
      }
    }

    &__button {
      height: 56px;
      width: 100%;
      > wb-text {
        @include wb-text('m', ( responsive: true, strong: true ));
      }
    }

  }
  
  