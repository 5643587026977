@import '@workbench/core/dist/scss/utility.scss';

.footer {
	padding: 20px 0px;
	.footer__links {
		min-height: 40px !important;
		height: max-content;
		gap: var(--wb-spacing-xs) var(--wb-spacing-xs) !important;
		flex-wrap: wrap;
		float: left;

		@include breakpoint-between(mq1, mq3) {
			width: 85%;
		}

		> wb-link {
			--color: var(--wb-white) !important;
		}
		> wb-link:hover {
			--color: #fff !important;
		}
	}
}
